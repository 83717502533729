<template>
  <div class="home">
    <x-header>实验百事通</x-header>

    <x-content
      :contentStyle="{
        display: 'block',
        width: 'calc(100% - 32px)',
        padding: '16px',
      }"
    >
      <x-card>
        <IntellectChat />
      </x-card>
    </x-content>
  </div>
</template>
<script>
import XCard from "../../components/busiess/XCard.vue";
import IntellectChat from '@/components/base/IntellectChat.vue';
export default {
  components: { XCard,IntellectChat },
  name: "Chat",
  data() {
    return {

    };
  },
  filters: {},
  created() {},
  computed: {},
  mounted() {
  },
  methods: {


  },
};
</script>
<style lang="scss" scoped>

.home {
  width: 100%;
  height: 100%;
  overflow: auto;
}
</style>